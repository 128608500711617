import React, { Component, useEffect } from 'react';
import '../App.css';
import "aos/dist/aos.css";
import Aos from 'aos';
import nft from '../assets/ss.png';
import poly from '../assets/polygon-pos.svg';
import avalanche from '../assets/avalanche.svg';
import bnb from '../assets/bnb.svg';
import base from '../assets/base.svg';
import eth from '../assets/ethereum.svg';
import icon from '../assets/queen.png';
import icon2 from '../assets/crown.png';

const Stry = () => {
    useEffect(() => {
        Aos.init({ duration: 4000 });
    }, [])
}

const ethdm = () => {
    window.open("https://dm-eth.pages.dev");
}

const ethdw = () => {
    window.open("https://dw-eth.pages.dev");
}

const polydm = () => {
    window.open("https://dm-poly.pages.dev");
}

const polydw = () => {
    window.open("https://dw-poly.pages.dev");
}

const bscdm = () => {
    window.open("https://dm-bsc.pages.dev");
}

const avaxdm = () => {
    window.open("https://diamonddao.art/");
}

const bscdw = () => {
    window.open("https://dw-bsc.pages.dev");
}


const dwsol = () => {
    window.open("https://dw-sol.vercel.app/");
}

class Story extends Component {

    render() {
        return (

            <div class="boxWrap2Story">

                <div class="about">
                    <div class="storyCon">
                        <div class="conT">About <span class="hl2">US </span></div>
                        <p>Welcome to the DDR Throne Room, where art and technology converge to create a one-of-a-kind adventure. We are a dedicated team of artists, developers, and space enthusiasts who believe that every pixel has a story to tell. Our mission is to offer you multiple NFT collections that capture your imagination while promoting inclusivity through a gateway to our overseer, the Diamondback Stablecoin. Join us and experience a world where creativity knows no bounds.</p>
                        <p>Prepare to embark on a journey that blurs the lines between creativity and accessibility! With DDR, you're not just a collector – you're a pioneer and owner in the metaverse, embracing innovation and connection with every Diamond Family masterpiece. Holding certain DDR NFTs doesn't just dazzle you with amazing digital art; it also provides a gateway to becoming a Royal Token holder, rewarding you for decades under our overseer's guidance. Join us and experience the future of art and ownership!</p>
                    </div>

                    <img src={nft} />

                </div>

                <div class="chainsMain" data-aos="fade-up" data-aos-duration="500">

                    <div class="cHeader">Diamond DAO on leading EVM chains</div>
                    <div className='chainT'>Add following networks into your Metamask with a single click</div>

                    <div class="icons2">
                        <img src={eth} />
                        <img src={poly} />
                        <img src={avalanche} />
                        <img src={bnb} />
                        <img src={base} />
                    </div>

                    <div className='mintChains'>
                        <div className='nameAndIcon' onClick={ethdw}>
                            <img src={icon} />
                            <div>ETH Diamond woman</div>
                        </div>

                        <div className='nameAndIcon' onClick={ethdm}>
                            <img src={icon2} />
                            <div>ETH Diamond Man</div>
                        </div>

                        <div className='nameAndIcon' onClick={polydw}>
                            <img src={icon} />
                            <div>MATIC Diamond Woman</div>
                        </div>

                        <div className='nameAndIcon' onClick={polydm}>
                            <img src={icon2} />
                            <div>MATIC Diamond Man</div>
                        </div>

                        <div className='nameAndIcon' onClick={bscdw}>
                            <img src={icon} />
                            <div>BSC Diamond Woman</div>
                        </div>

                        <div className='nameAndIcon' onClick={bscdm}>
                            <img src={icon2} />
                            <div>BSC Diamond Man</div>
                        </div>

                        <div className='nameAndIcon' onClick={avaxdm}>
                            <img src={icon2} />
                            <div>AVAX Diamond Man</div>
                        </div>

                        <div className='nameAndIcon'>
                            <img src={icon} alt="icon" />
                            <div className="hoverText"></div>
                        </div>

                        <div className='nameAndIcon'>
                            <img src={icon2} />
                            <div className="hoverText2"></div>
                        </div>

                        <div className='nameAndIcon' onClick={dwsol}>
                            <img src={icon} />
                            <div>SOLANA Diamond Woman</div>
                        </div>

                    </div>

                </div>

            </div>
        )
    }
}

export default Story;

