import React, { Component, useEffect } from 'react';
import '../App.css';
import "aos/dist/aos.css";
import Aos from 'aos';
import icon1 from '../assets/1f.jpg';
import icon3 from '../assets/3f.jpg';
import icon4 from '../assets/4f.jpg';

const Stry = () => {
    useEffect(() => {
        Aos.init({ duration: 4000 });
    }, [])
}

class About extends Component {

    render() {
        return (

            <div class="boxWrap2Story-about">

                <div class="about">
                    <div class="conT3">Diamond <span className='hl'>DAO</span></div>
                    <div class="storyCon">
                        <p>
                            Welcome to Diamond DAO Royalty! Here, art meets blockchain in a spectacular fusion of creativity and utility. Dive into a world where limitless imagination shapes extraordinary digital experiences. DDR is not just various NFT collections—it's an exclusive community spanning multiple blockchains, redefining the future of digital art and ownership.
                        </p>
                        <p>Prepare to embark on a mesmerizing adventure where the Diamond Family dazzles you with artistic rarities and unlocks hidden treasures beyond your wildest dreams! Our mission is to expand our brand while rewarding our loyal holders through profit-sharing. As pioneers in this endeavor, we are committed to giving back to our Royal Family and beyond. We proudly support the underprivileged and unbanked, making significant donations to various charities.</p>

                        <p>
                            Join us in this exciting journey of art, innovation, and compassion!
                        </p>

                    </div>
                </div>

                <div className='iconsMain'>
                    <img id="icon3F" src={icon3} />
                    <img id="icon1F" src={icon1} />
                    <img id="icon4F" src={icon4} />

                </div>
            </div>
        )
    }
}

export default About;

