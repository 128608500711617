import React, { Component } from 'react';

class FAQ extends Component {


  render() {
    return (

      <div id="faq" class="faqBg">

        <div class="conT2">FREQUENTLY ASKED <span class="hl2">QUESTIONS</span></div>

        <div class="accordin reveal">

          <details data-aos="fade-up" data-aos-duration="1000">
            <summary>How many different NFT collections are there in the Diamond Dao Royalty?</summary>
            <div class="faq__content">

              <p><li>DiamondMan</li></p>
              <p><li>DianmondWoman</li></p>
              <p><li>DiamondChild</li></p>

            </div>
          </details>
          <details data-aos="fade-up" data-aos-duration="1000">
            <summary>How are NFT holders rewarded?</summary>
            <div class="faq__content">
              
            <p><li>NFT holders own fractionalized units of Royalty.  Each mint has a chance to 100x their mint cost which has a 2.5% chance of occurring. (better odds than a sh*t coin and you own the Royalty no matter what) </li> </p>
            <p><li>Holding a DiamondWoman NFT grants you access to the Royal Token Throne Room.(Portal)
                </li> </p>
             
            </div>
          </details>
          <details data-aos="fade-up" data-aos-duration="1000">
            <summary>Where do the rewards come from?</summary>
            <div class="faq__content">
              <p><li>The rewards come from sales of the DiamondBack Stable coin! Through it’s success we can all bask in rewards for 300 months.</li></p>
            </div>
          </details>

        </div>

      </div>


    )
  }
}

export default FAQ;

