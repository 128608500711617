import React, { Component, useEffect } from 'react';
import '../App.css';
import "aos/dist/aos.css";
import Aos from 'aos';
import os from '../assets/os.png';
import twitter from '../assets/twitter.png';
import discord from '../assets/discord.png';
import road from '../assets/road.png';

const opensea = () => {
    window.open("#");
}

const tweet = () => {
    window.open("#");
}

const tg = () => {
    window.open("#");
}

const element = () => {
    window.open("#");
}

const Stry = () => {
    useEffect(() => {
        Aos.init({ duration: 4000 });
    }, [])
}


class Footer extends Component {




    render() {
        return (

            <div class="boxWrap2Footer">

                <div class="footer">


                    <div class="icons">
                        <img id="icon1" onClick={opensea} src={os} />
                        <img id="icon2" onClick={element} src={discord} />
                        <img id="icon3" onClick={tweet} src={twitter} />
                    </div>

                    <div class="copyright">Copyright © 2024 Diamond DAO. All Rights Reserved</div>

                    <div class="glitch-wrapper2">
                        <div class="glitch2" data-text="Diamond DAO" onClick={() => window.location.href = '/'}>Diamond DAO</div>
                    </div>
                </div>

                <img class="road" src={road} />

            </div>
        )
    }
}

export default Footer;

