import React, { Component, useEffect } from 'react';
import '../App.css';
import "aos/dist/aos.css";
import Aos from 'aos';
import team1 from '../assets/2.png'
import team2 from '../assets/4.png'
import team3 from '../assets/11.png'


const Stry = () => {
    useEffect(() => {
        Aos.init({ duration: 4000 });
    }, [])
}

class Team extends Component {

    render() {
        return (

            <div class="boxWrapTeam">

                    <div class="about2">
                        <div class="conT2">TEAM</div>
                        <div class="teamSection"  data-aos="fade-in" data-aos-duration="1000">
                            <div class="box1">
                                <img src={team1}/>
                                <div class="name">BLAZE</div>
                                <div class="name2">Chief Astronomer</div>
                            </div>

                            <div class="box1">
                                <img src={team2}/>
                                <div class="name">MARVEL</div>
                                <div class="name2">Lead Developer</div>
                            </div>
                            
                            <div class="box1">
                                <img src={team3}/>
                                <div class="name">GUARD</div>
                                <div class="name2">Creative Visionary</div>
                            </div>
                         </div>

                    </div>
             </div>
        )
    }
}

export default Team;

